<template>
  <div class="container">
    <scroll ref="scroll" class="scroll" :data="data" @pullingUp="pullUpPush" :pull-up-load="true">
      <div>
        <div class="header">
          <router-link to="/home" tag="div" class="back">
            <div class="ico"></div>
            <div>返回</div>
          </router-link>
        </div>
        <div class="body">
          <div class="banner-bg">
            <div class="banner"></div>
            <div class="title-wrapper">
              <div class="title">
                <div class="ico"></div>
                <div>推荐图书</div>
              </div>
              <router-link tag="div" to="books-eva" class="more">
                <div>查看更多</div>
              </router-link>
            </div>
          </div>
          <div class="tab">
            <div class="tab-item active">电子书</div>
            <router-link tag="div" to="rank-eva" class="tab-item">排行榜</router-link>
          </div>
          <div class="books">
            <router-link tag="div" :to="`home/book-detail/${item.resources_guid}/码上悦读`" class="book-item"
                         v-for="(item,index) in books" :key="index">
              <div class="cover">
                <img class="img" :src="item.cover.media_file"/>
              </div>
              <div class="title">{{ item.resources_name }}</div>
            </router-link>
          </div>
          <div class="evaluation">
            <div class="eva-title">
              <div class="ico"></div>
              <div>填写评论</div>
            </div>
            <input v-model="form.evaluation_title" type="text" class="book-title" placeholder="请输入您阅读的书名">
            <div class="content-block">
              <textarea v-model="form.evaluation_desc" class="book-evaluation" placeholder="请输入评论内容"></textarea>
              <div class="submit" @click="handleSubmit">提交</div>
            </div>
            <div class="eva-title">
              <div class="ico"></div>
              <div>热门评论</div>
            </div>
            <div class="eva-tab">
              <div class="eva-tab-item" :class="{active: currentIndex === index}" v-for="(item,index) in tab"
                   :key="index" @click="handleTab(index)">{{ item.name }}
              </div>
            </div>
            <div class="item" v-for="(item,index) in evaluations" :key="index">
              <div class="user">
                <div class="avatar">
                  <img class="img" :src="item.c_user.avatar.media_file" alt="">
                </div>
                <div class="info">
                  <div class="name">{{ item.c_user.c_user_wxname }}</div>
                  <div class="time">{{ item.create_time }}</div>
                </div>
                <div class="good" :class="{active: item.is_evaluation === 1}" @click="handleGood(item)">
                  <div class="ico"></div>
                  <div>点赞({{ item.evaluation_good }})</div>
                </div>
              </div>
              <div class="content">
                {{ item.evaluation_desc }}
                <br>
                ---{{ item.evaluation_title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { books, createEvaluation, evaluations, goods } from '@/api/Evaluations'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'

export default {
  name: 'ActivityEva',
  components: { Scroll },
  computed: {
    ...mapGetters(['userGuid', 'agencyGuid'])
  },
  data () {
    return {
      data: [],
      form: {
        evaluation_title: '',
        evaluation_desc: ''
      },
      books: [],
      page: 1,
      limit: 15,
      total: 0,
      evaluations: [],
      tab: [
        {
          name: '热度最高',
          order: 'evaluation_good'
        },
        {
          name: '最新评论',
          order: 'create_time'
        }
      ],
      currentIndex: 0
    }
  },
  mounted () {
    this._books()
    this._evaluations()
  },
  methods: {
    _books () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      books({
        limit: 6
      }).then(res => {
        const {
          code,
          msg,
          data
        } = res
        if (code === 200) {
          this.books = data
          this.refresh()
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    _evaluations () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      evaluations({
        page: this.page,
        limit: this.limit,
        evaluation_status: 1,
        c_user_guid: this.userGuid,
        order: this.tab[this.currentIndex].order
      }).then(res => {
        const {
          code,
          msg,
          data
        } = res
        if (code === 200) {
          this.evaluations = this.evaluations.concat(data.list)
          this.total = data.total
          this.refresh()
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    handleGood (item) {
      if (item.is_evaluation === 1) {
        return false
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      goods({
        evaluation_guid: item.evaluation_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        const {
          code,
          msg
        } = res
        if (code === 200) {
          item.is_evaluation = 1
          item.evaluation_good += 1
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    handleSubmit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      createEvaluation({
        evaluation_user: this.userGuid,
        evaluation_agency: this.agencyGuid,
        ...this.form
      }).then(res => {
        const {
          code,
          msg
        } = res
        if (code === 200) {
          this.form = {
            evaluation_title: '',
            evaluation_desc: ''
          }
          Toast.success('评价成功,请等待审核')
        } else {
          Toast.fail(msg)
        }
      })
    },
    handleTab (index) {
      this.currentIndex = index
      this.page = 1
      this.evaluations.splice(0, this.evaluations.length)
      this._evaluations()
    },
    pullUpPush () {
      if (this.total <= this.evaluations.length) {
        return true
      }
      this.page++
      this._evaluations()
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.img
  width 100%
  height 100%

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background rgba(248, 248, 248, 1)

    .header
      width 100%
      height 212px
      bg-image2('assets/activity-evaluation/header-bg')
      background-size 100% 100%
      background-repeat no-repeat

      .back
        display flex
        align-items center
        justify-content center
        width 200px
        height 80px
        font-size 26px
        color rgba(51, 51, 51, 1)

        .ico
          width 16px
          height 28px
          margin-right 10px
          bg-image('~@/assets/img/reading/back')
          background-size 100% 100%
          background-repeat no-repeat

    .body
      width 100%

      .banner-bg
        position relative
        width 100%
        height 358px
        bg-image2('assets/activity-evaluation/bg')
        background-size 100% 100%
        background-repeat no-repeat

        .banner
          position absolute
          left 50%
          top -132px
          transform translate(-50%, 0)
          width 670px
          height 400px
          bg-image2('assets/activity-evaluation/banner')
          background-size 100% 100%
          background-repeat no-repeat

        .title-wrapper
          position absolute
          bottom 0
          left 50%
          transform translate(-50%, 0)
          display flex
          align-items center
          width 670px
          height 90px

          .title
            display flex
            align-items center
            width auto
            height 100%
            font-size 34px
            color rgba(60, 60, 60, 1)
            font-weight bold

            .ico
              width 54px
              height 48px
              margin-right 10px
              bg-image2('assets/activity-evaluation/title-ico')
              background-size 100% 100%
              background-repeat no-repeat

          .more
            display flex
            align-items center
            width auto
            height 90px
            margin-left auto
            font-size 26px
            color rgba(140, 139, 139, 1)

      .tab
        display flex
        align-items center
        justify-content space-around
        width 670px
        height 104px
        margin 0 auto
        background rgba(255, 255, 255, 1)
        border-radius 20px

        .tab-item
          display flex
          align-items center
          justify-content center
          width 202px
          height 60px
          font-size 30px
          color rgba(60, 60, 60, 1)

          &.active
            color rgba(255, 255, 255, 1)
            bg-image2('assets/activity-evaluation/tab-active')
            background-size 100% 100%
            background-repeat no-repeat

      .books
        display flex
        justify-content space-between
        flex-wrap wrap
        width 670px
        margin 34px auto 0 auto

        .book-item
          width 208px

          .cover
            width 208px
            height 290px
            border-radius 14px
            overflow hidden

          .title
            width 100%
            margin 20px 0
            color rgba(51, 51, 51, 1)
            font-size 26px
            line-height 30px
            no-wrap2(2)

      .evaluation
        width 100%

        .eva-title
          display flex
          align-items center
          width 670px
          height 126px
          margin auto
          font-size 34px
          color rgba(60, 60, 60, 1)

          .ico
            width 56px
            height 56px
            margin-right 10px
            font-weight bold
            bg-image2('assets/activity-evaluation/hot-ico')
            background-size 100% 100%
            background-repeat no-repeat

        .book-title
          display flex
          align-items center
          width 670px
          height 120px
          margin 0 auto
          padding 34px
          box-sizing border-box
          font-size 28px
          background rgba(255, 255, 255, 1)
          border-radius 16px
          border none

        .content-block
          width 670px
          margin 20px auto 0 auto
          background rgba(255, 255, 255, 1)
          border-radius 16px
          overflow hidden

          .book-evaluation
            width 100%
            height 200px
            padding 34px
            box-sizing border-box
            font-size 28px
            resize none
            border none

          .submit
            display flex
            align-items center
            justify-content center
            width 190px
            height 74px
            margin-left auto
            color rgba(255, 255, 255, 1)
            background rgba(210, 87, 68, 1)
            border-radius 40px 0 0 0

        .eva-tab
          display flex
          align-content end
          width 670px
          height 50px
          padding 50px 40px 0 40px
          margin 0 auto
          font-size 26px
          color rgba(182, 182, 182, 1)
          border-radius 30px 30px 0 0
          background rgba(255, 255, 255, 1)

          .eva-tab-item
            display flex
            align-items center
            justify-content center
            width 138px
            height 50px
            margin-right 20px

            &.active
              font-weight bold
              color rgba(60, 60, 60, 1)
              background rgba(239, 239, 239, 1)

        .item
          width 100%
          padding-top 50px
          background rgba(255, 255, 255, 1)
          border-bottom 10px solid rgba(245, 245, 245, 1)

          .user
            display flex
            align-items center
            width 670px
            margin 0 auto

            .avatar
              width 74px
              height 74px
              border-radius 50%
              overflow hidden

            .info
              display flex
              align-content space-around
              flex-wrap wrap
              width 400px
              height 74px
              padding-left 20px
              box-sizing border-box

              .name
                width 100%
                font-size 30px
                color rgba(5, 5, 5, 1)
                no-wrap()

              .time
                width 100%
                font-size 24px
                color rgba(202, 202, 202, 1)

            .good
              display flex
              align-items center
              width auto
              height 74px
              margin-left auto
              font-size 24px
              color rgba(166, 166, 166, 1)

              &.active
                color rgba(210, 87, 68, 1)

                .ico
                  bg-image2('assets/activity-evaluation/good-active')
                  background-size 100% 100%
                  background-repeat no-repeat

              .ico
                width 30px
                height 28px
                margin-right 14px
                bg-image2('assets/activity-evaluation/good')
                background-size 100% 100%
                background-repeat no-repeat

          .content
            width 100%
            padding 40px 40px 40px 138px
            box-sizing border-box
            font-size 28px
            color rgba(18, 18, 18, 1)
            line-height 36px
</style>
